import React, { useState } from "react";
import { toast } from "react-toastify";
import { IoIosClose } from 'react-icons/io';

import { COLORS } from "../../../utils/constants";
import { cpfMask, maskPhone } from "../../../utils/masks";

import SecondStep from "./SecondStep";
import { useWindowSize } from "../../Login";
import Input from "../../../components/Input/Input";
import Select from "../../../components/Select/Select";
import CheckBox from "../../../components/CheckBox/CheckBox";

import { Alert, EditGroup, EditText, EditWrapper, InputWrapper, Instructions, PhoneWrapper, SubmitButton, Title } from "../styles";

/* =============================== TYPES =============================== */

type Props = {
    nome: string;
    rg: string;
    ufRg: string;
    cpf: string;
    telefone1: string;
    telefone2: string;
    isWhatsapp1: boolean;
    isWhatsapp2: boolean;
    sexo: string;
    estadoCivil: string;
    profissao: string;
    dataNascimento: string;
    email: string;
    firstStep: boolean;
    setFirstStep: (e: boolean) => void;
}

/* =============================== COMPONENT =============================== */

const FirstStep: React.FC<Props> = ({ nome, rg, ufRg, cpf, telefone1, telefone2, isWhatsapp1, isWhatsapp2, sexo, estadoCivil, profissao, email, dataNascimento, firstStep, setFirstStep }) => {

    /* =============================== CONSTANTS / STATES =============================== */

    const screen = useWindowSize();

    const [name, setName] = useState(nome);
    const [registration, setRegistration] = useState(rg);
    const [genre, setGenre] = useState(sexo);
    const [ufRG, setUfRG] = useState(ufRg);
    const [userCPF, setUserCPF] = useState(cpf);
    const [birthday, setBirthday] = useState(dataNascimento);
    const [status, setStatus] = useState(estadoCivil);
    const [emailAdress, setEmailAdress] = useState(email);
    const [phone1, setPhone1] = useState(telefone1);
    const [phone2, setPhone2] = useState(telefone2);
    const [occupation, setOccupation] = useState(profissao);
    const [isWhats1, setIsWhats1] = useState(isWhatsapp1);
    const [isWhats2, setIsWhats2] = useState(isWhatsapp2);
    const [secondStep, setSecondStep] = useState(false);

    /* =============================== FUNCTIONS =============================== */

    const handlePrevious = () => {
        setSecondStep(false);
    }

    const handleNext = () => {
        if (name?.length < 3) {
            toast.warn("Atenção: Preencha o nome com pelo menos 3 caracteres.");
            return;
        }
        
        if (!registration || registration.length < 5) {
            toast.warn("Atenção: Preencha o RG com pelo menos 5 dígitos");
            return;
        }
        
        if (!ufRG || ufRG.length !== 2) {
            toast.warn("Atenção: Preencha o UF do RG corretamente");
            return;
        }
        if (!birthday || birthday === null || birthday.length != 10) {
            toast.warn('Atenção: A data de nascimento é obrigatória');
            return;
        }
        if (!cpf || cpf.length !== 14) {
            toast.warn("Atenção: Preencha o CPF corretamente");
            return;
        }
        
        if (!phone1 && !phone2) {
            toast.warn("Atenção: Preencha pelo menos um campo de telefone");
            return;
        } else if (phone1 && phone1.length != 15) {
            toast.warn("Atenção: Telefone 1 incompleto");
            return;
        } else if (phone2 && (phone2.length < 14 || phone2.length > 15)) {
            toast.warn("Atenção: Telefone 2 incompleto");
            return;
        } 

        if (!emailAdress || emailAdress.length < 3) {
            toast.warn("Atenção: O email é obrigatório.");
            return;
        }

        setSecondStep(true);
        return;
    }

    /* =============================== RENDERS =============================== */

    return (
        <>
            <EditGroup>
                <div style={{ width: 30 }} />
                <Title style={{ textAlign: "center" }}>Atualização de Dados</Title>
                <EditWrapper onClick={() => setFirstStep(false)}>
                    <IoIosClose size={30} color={COLORS.PRIMARY} />
                    <EditText>cancelar</EditText>
                </EditWrapper>
            </EditGroup>

            {!secondStep &&
                (
                    <div>

                        <Instructions>Siga as etapas, informando seus dados atuais, para submeter sua atualização cadastral.</Instructions>

                        <div style={{ margin: "10px auto", textAlign: "center" }}>
                            <Alert>Esta atualização não será realizada imediatamente.</Alert>
                            <Alert>Sujeita a análise.</Alert>
                        </div>

                        <Input label="Nome" required fontColor={COLORS.PRIMARY} value={name} onChange={(e) => setName(e.target.value)} placeholder="Nome" style={{ width: "100%" }} />

                        <Input label="RG" required fontColor={COLORS.PRIMARY} value={registration} onChange={(e) => setRegistration(e.target.value)} placeholder="RG" style={{ width: "100%" }} />

                        <Select
                            options={arrayUF}
                            label="UF do RG"
                            value={ufRG}
                            onChange={(e: any) => setUfRG(e.target.value)}
                            style={{ width: screen.sizeWidth <= 520 ? "100%" : 400, color: `${COLORS.PRIMARY}` }}
                            padding
                            margintop={8}
                        />

                        <Input label="CPF" required fontColor={COLORS.PRIMARY} value={userCPF} onChange={(e) => setUserCPF(cpfMask(e.target.value))} placeholder="000.000.000-00" style={{ width: "100%" }} />

                        {!dataNascimento && (
                            <Input type="date" fontColor={COLORS.PRIMARY} label="Data de nascimento" placeholder="00/00/0000" value={birthday} onChange={(e: any) => setBirthday(e.target.value)} marginTop={8} style={{ width: 400 }} />
                        )
                        }

                        <PhoneWrapper>
                            <Input label="Telefone 1" required fontColor={COLORS.PRIMARY} value={phone1} onChange={(e) => setPhone1(maskPhone(e.target.value))} placeholder="DDD + número" />

                            <CheckBox label="Marcar como whatsapp" checked={isWhats1} onChange={() => setIsWhats1(!isWhats1)} lableColor={COLORS.PRIMARY} marginTop={0} checkboxOrder="1" marginLeft={10} />
                        </PhoneWrapper>

                        <PhoneWrapper>
                            <Input label="Telefone 2" fontColor={COLORS.PRIMARY} value={phone2} onChange={(e) => setPhone2(maskPhone(e.target.value))} placeholder="DDD + número" />

                            <CheckBox label="Marcar como whatsapp" checked={isWhats2} onChange={() => setIsWhats2(!isWhats2)} lableColor={COLORS.PRIMARY} marginTop={0} checkboxOrder="1" marginLeft={10} />
                        </PhoneWrapper>

                        <Input label="Email" required fontColor={COLORS.PRIMARY} value={emailAdress} onChange={(e) => setEmailAdress(e.target.value)} placeholder="nome@email.com" type="email" style={{ width: "100%" }} />

                        <InputWrapper>
                            <SubmitButton onClick={handleNext}>Próximo</SubmitButton>
                        </InputWrapper>

                    </div>
                )
            }

            {
                secondStep && (
                    <SecondStep name={name} registration={registration} ufRG={ufRG} userCPF={userCPF} phone1={phone1} phone2={phone2} isWhats1={isWhats1} isWhats2={isWhats2} genre={genre} status={status} occupation={occupation} birthday={birthday} emailAdress={emailAdress} handlePrevious={handlePrevious} />
                )
            }
        </>
    )
}

/* =============================== OBJECTS =============================== */

const arrayUF = [
    { value: '', label: 'Selecione uma opção' },
    { value: 'AC', label: 'AC' },
    { value: 'AL', label: 'AL' },
    { value: 'AP', label: 'AP' },
    { value: 'AM', label: 'AM' },
    { value: 'BA', label: 'BA' },
    { value: 'CE', label: 'CE' },
    { value: 'DF', label: 'DF' },
    { value: 'ES', label: 'ES' },
    { value: 'GO', label: 'GO' },
    { value: 'MA', label: 'MA' },
    { value: 'MT', label: 'MT' },
    { value: 'MS', label: 'MS' },
    { value: 'MG', label: 'MG' },
    { value: 'PA', label: 'PA' },
    { value: 'PB', label: 'PB' },
    { value: 'PR', label: 'PR' },
    { value: 'PE', label: 'PE' },
    { value: 'PI', label: 'PI' },
    { value: 'RJ', label: 'RJ' },
    { value: 'RN', label: 'RN' },
    { value: 'RS', label: 'RS' },
    { value: 'RO', label: 'RO' },
    { value: 'RR', label: 'RR' },
    { value: 'SC', label: 'SC' },
    { value: 'SP', label: 'SP' },
    { value: 'SE', label: 'SE' },
    { value: 'TO', label: 'TO' },
];

export default FirstStep;